import React, { useEffect, useState } from "react";
import { ICollpsable } from "../ICollpsable";
import { isNonEmptyArray } from "../../utils/array";
import ApplicatorCard from "../../shared_components/cam_components/ApplicatorCard";
import Icons from "../../shared_components/ui_component_library/Icons";
import { Button, Dropdown, Select, Tooltip, Checkbox } from "antd";
import { useAlert } from 'react-alert'
import { ITable, ITableBody, ITableHeader, ITableRow, ITD, ITH } from "../../shared_components/ui_component_library/ITable";
import { canUserPublish } from "../Modals/RulesRestructure";
import IButton from "../../shared_components/ui_component_library/IButton";
import { ButtonsDropdown } from "../../shared_components/ui_component_library/ButtonsDropdown";
import { handleError } from "../../utils/errors";
import repopulate_panel from "../../utils/panel_repopulation";

const CamFlowRightPanelCard = ({ applicator, applicator_card_props, ...props }) => {
    const isPushblishDisabled = (connector) => {
        return !(connector && connector.is_fe) || !canUserPublish()
    }

    const change_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_change_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const remove_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_remove_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const edit_on_click = async () => {
        var form = {}
        form["selected_connector_id"] = applicator.id;
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_edit_connector_modal(JSON.stringify(form)));
        window.handle_ui_response(resp);
    }

    const publish_on_click = async () => {
        let resp = await window.Promisify(window.Module.publish_cam_applicator(applicator.id));
        console.log("publish response", resp)
        window.handle_ui_response(resp);
    }

    return (
        <div className="rounded" style={{ border: '1px solid #6B4EFF', overflow: 'hidden' }} {...props}>
            <ApplicatorCard edit_selected_applicator={edit_on_click} o={applicator} {...applicator_card_props} dropdown_buttons={[
                // {
                //     display_name: "Change connector",
                //     customOnClick: change_on_click
                // },
                // {
                //     display_name: "Remove connector",
                //     customOnClick: remove_on_click,
                //     color: '#FF5247'
                // }
                {
                    display_name: "Publish",
                    disabled: isPushblishDisabled(applicator),
                    customOnClick: publish_on_click,
                    tooltip: isPushblishDisabled(applicator) ? !canUserPublish() ? "You need catalogue manager or production designer access to publish" : "Only connectors created within design can be published" : ""
                }
            ]} />
        </div>
    )
}

const ConnectorLegend = () => (<div style={{width: '6px', aspectRatio: '1 / 1 ', borderRadius: '50%', background: 'lightgreen'}} />)

const CamFlowRightPanel = ({ cam_mode_data, update_view, handle_ui_response }) => {

    const alert = useAlert();

    // const [ open_scenario, set_open_scenario ] = useState('');
    // const [create_scenario_open, set_create_scenario_open] = useState(false);
    // const [ active_panel, set_active_panel ] = useState('');
    
    //create scenario parameters
    const [connection_type, set_connection_type] = useState('hinge');
    //panel 1 is always cam_mode_active_panel
    const [panel2, set_panel2] = useState('');
    const [face1, set_face1] = useState('');
    const [face2, set_face2] = useState('');
    const [flip_axes, set_flip_axes] = useState('');

    const does_scenario_already_exist = () => {
        if(cam_mode_data && cam_mode_data.scenarios && cam_mode_data.scenarios.length){
            var scenario_find = cam_mode_data.scenarios.find(scenario => {
                if(scenario.is_user_added_connector_set && !(scenario.converted_from_auto_deduced) && scenario.attached_panels && scenario.attached_panels.length > 1){
                    if(scenario.attached_panels[0].id === cam_mode_data.cam_mode_active_panel && scenario.attached_panels[1].id === panel2){
                        return true;
                    }
                }

                return false
            })

            if(scenario_find){
                return scenario_find;
            }
        }
        return false;
    }


    useEffect(() => {
        // if(!cam_mode_data || active_panel !== cam_mode_data.cam_mode_active_panel){
            // set_active_panel(cam_mode_data && cam_mode_data.cam_mode_active_panel || '');
            set_connection_type('hinge');
            set_panel2('');
            set_face1('');
            set_face2('');
            // set_create_scenario_open(false);
        // }
    }, [cam_mode_data])

    const handleChange = (val, property) => {
        if(property === 'connection_type'){
            set_connection_type(val);
        }else if(property === 'panel2'){
            set_panel2(val);
            window.Module.set_new_scenario_second_panel_interface(val);
        }else if(property === 'face1'){
            set_face1(val);
        }else if(property === 'face2'){
            set_face2(val);
        }
    }

    const onclick_create_scenario = () => {
        if(!panel2){
            alert.error("Please select panel 2");
            return;
        }
        if(!face1){
            alert.error("Please select face 1");
            return;
        }
        if(!face2){
            alert.error("Please select face 2");
            return;
        }
        
        var form = {
            "connection_type": connection_type,
            "panel1": cam_mode_data.cam_mode_active_panel,
            "face1": face1,
            "panel2": panel2,
            "face2": face2,
            "flip_axes": flip_axes,
        }
        var existing_scenario = does_scenario_already_exist();
        if(existing_scenario){
            form["existing_scenario_id"] = existing_scenario.id;
        }

        var resp = window.Module.create_or_edit_custom_scenario_from_cam_mode(JSON.stringify(form));
        handle_ui_response(resp);
    }

    const toggle = (scenario) => {
        if(scenario == "create_scenario"){
            // set_create_scenario_open(!create_scenario_open);
            if(window.Module.get_create_scenario_flow_active()){
                window.Module.set_cam_mode_active_connector_set('');
            }else{
                window.Module.set_cam_mode_active_connector_set('create_scenario');
            }
        }else{
            window.Module.set_cam_mode_active_connector_set(scenario.active ? '' : scenario.id);
        }
        window.three_interface.em_interface.needs_redraw = true;
        window.two_interface.em_interface.needs_redraw = true;
        update_view();
    }
    
    const add_on_click = async () => {
        // var form = {}
        // form["selected_connector_id"] = applicator.id;
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_add_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const change_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_change_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const remove_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_remove_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    let panel_types_map = {}
    if(cam_mode_data && cam_mode_data.panel_type_options){
        cam_mode_data.panel_type_options.forEach(panel => {
            panel_types_map[panel.value] = panel;
        })
    }

    let active_panel_unique_label = () => {
        let active_panel_name = cam_mode_data && cam_mode_data.cam_mode_active_panel_name ||  "";
        // Example panel name "C1.P2 (Right Panel)", unique label - C1.P2
        if(active_panel_name){
            return active_panel_name.split(" (")[0];
        }
        return "";
    }

    let is_scenario_deletable = (scenario) => {
        return scenario.is_user_added_connector_set && !(scenario.converted_from_auto_deduced)
    }


    let remove_scenario = async (scenario_id) => {
        let ui_response = window.Module.remove_custom_scenario_from_cam_mode(scenario_id);
        handle_ui_response(ui_response);
    }

    let edit_scenario = (scenario_id) => {
        if(cam_mode_data && cam_mode_data.scenarios && cam_mode_data.scenarios.length){
            var scenario_find = cam_mode_data.scenarios.find(scenario => scenario.id == scenario_id);
            if(scenario_find && scenario_find.attached_panels.length > 1){
                var form = {id: scenario_find.attached_panels[0].id};
                window.Module.select_panel_for_cam_mode(JSON.stringify(form));
                repopulate_panel();
                toggle("create_scenario");
                
                setTimeout(() => {
                    set_panel2(scenario_find.attached_panels[1].id);
                    set_face1(scenario_find.attached_panels[0].side.toLowerCase());
                    set_face2(scenario_find.attached_panels[1].side.toLowerCase());
                    set_connection_type(scenario_find.connection_type.split("_")[1]);
                    set_flip_axes(scenario_find.flip_axes);
                },100)

            }
        }
    }

    const getScenariosViolationTooltip = (scenario) => {
        try{
            let panel_collisions = cam_mode_data && cam_mode_data.collisions && cam_mode_data.collisions.filter(x => x.panel === cam_mode_data.cam_mode_active_panel);
            let scenario_violations = [];
            if(panel_collisions){
                scenario_violations = panel_collisions.filter(x => x.scenario === scenario.id);

                if(scenario_violations.length){
                    let tooltip_message = "";
                    let collistion_types = {};
                    scenario_violations.map(o => {
                        if(!collistion_types[o.name]){
                            collistion_types[o.name] = 0;
                        }
                        collistion_types[o.name] += 1;
                    })

                    Object.keys(collistion_types).map(key => {
                        if(tooltip_message != ""){
                            tooltip_message += ", ";
                        }
                        tooltip_message += (key + " (" + collistion_types[key] + ")");
                    })

                    return tooltip_message;
                }
            }

            return "";
        }catch(err){
            console.error(`Error in getScenariosViolationTooltip `, err)
            handleError(err);
        }
    }

    const getConectorViolationTooltip = (scenario, connector_id) => {
        try{
            let panel_collisions = cam_mode_data && cam_mode_data.collisions && cam_mode_data.collisions.filter(x => x.panel === cam_mode_data.cam_mode_active_panel);
            let scenario_collisions = panel_collisions && panel_collisions.filter(x => x.scenario === scenario.id);
            if(scenario_collisions){
                let connector_collisions = scenario_collisions.filter(x => x.connector === connector_id);
                if(connector_collisions.length){
                    let tooltip_message = "";
                    let collistion_types = {};
                    connector_collisions.map(o => {
                        if(!collistion_types[o.name]){
                            collistion_types[o.name] = 0;
                        }
                        collistion_types[o.name] += 1;
                    })

                    Object.keys(collistion_types).map(key => {
                        if(tooltip_message != ""){
                            tooltip_message += ", ";
                        }
                        tooltip_message += (key + " (" + collistion_types[key] + ")");
                    })

                    return tooltip_message;
                }

            }
            return "";
        }catch(err){
            console.error(`Error in getScenariosViolationTooltip `, err)
            handleError(err);
        }
    }


    return (
        <>
            <div style={{ textAlign: 'left', width: '400px', background: 'white', position: 'absolute', top: 'var( --navbar_height_design , 52px)', right: '4px', zIndex: '999', height: 'calc(100vh - var( --navbar_height_design , 52px))' }}>
                {
                    cam_mode_data && cam_mode_data.cam_mode_active_panel ?
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div className="inf-border inf-py-2 inf-px-5 flex_between" style={{ borderWidth: '2px' }}><b>{`Scenario List of ${active_panel_unique_label()}`}</b> <div className="flex_property inf-gap-2 inf-text-xs"><ConnectorLegend/> No of connectors</div></div>
                            {/* <div className="inf-border inf-py-1 inf-px-5 flex_property inf-gap-2"><ConnectorLegend /> Connector mapped to the scenario</div> */}
                            <div style={{ flex: 1, minHeight: '0px', overflow: 'auto' }}>
                                <ICollpsable header={"Create/Edit custom scenario"} toggle={() => toggle("create_scenario")} isOpen={window.Module.get_create_scenario_flow_active()} style_content_inner={{padding:"10px 16px"}} style_header={{borderBottom: !window.Module.get_create_scenario_flow_active() ? '1px solid var(--light-border-color, #c5c7cf)' : '' }}>
                                    <div style={{ overflow: 'hidden', display:"flex", flexFlow:"column",fontSize:"14px",gap:"16px" }}>
                                        <div style={{display:"grid",gridTemplateColumns:"1fr 2fr",alignItems:"center"}}>
                                            <div>
                                                Scenario Type:
                                            </div>
                                            <div>
                                                <Select name="connection_type" label="Scenario type" value={connection_type} onChange={(val) => handleChange(val, 'connection_type')} style={{width:"100%"}}>
                                                    <option value="hinge">Hinge</option>
                                                    <option value="dowel">Dowel</option>
                                                </Select>
                                            </div>
                                        </div>
                                        
                                        <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                            <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                                <div>Select first panel</div>
                                                <div style={{width:"16px", height:"16px", backgroundColor:"#FFD991"}}></div>
                                            </div>
                                            <div style={{display:"grid",gridTemplateColumns:"1fr 4fr"}}>
                                                <div>
                                                    Panel 1:
                                                </div>
                                                <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                                    <Select disabled={true} value={cam_mode_data.cam_mode_active_panel} style={{width:"100%"}}>
                                                        <option value={cam_mode_data.cam_mode_active_panel}>{cam_mode_data.cam_mode_active_panel_name}</option>
                                                    </Select>
                                                    <Select value={face1} onChange={(val) => handleChange(val, 'face1')} style={{width:"100%"}}>
                                                        <option value="">Select face</option>
                                                        <option value="top">Top</option>
                                                        <option value="bottom">Bottom</option>
                                                        <option value="left">Left</option>
                                                        <option value="right">Right</option>
                                                        <option value="back">Back</option>
                                                        <option value="front">Front</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                            <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                                <div>Select second panel</div>
                                                <div style={{width:"16px", height:"16px", backgroundColor:"#FDB1D4"}}></div>
                                            </div>
                                            <div style={{display:"grid",gridTemplateColumns:"1fr 4fr"}}>
                                                <div>
                                                    Panel 2:
                                                </div>
                                                <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                                    <Select value={panel2} onChange={(val) => handleChange(val, 'panel2')} style={{width:"100%"}}>
                                                        <option value="">Select panel</option>
                                                        {
                                                            cam_mode_data && cam_mode_data.other_panels && cam_mode_data.other_panels.map(panel => (
                                                                <option value={panel.id}>{panel.name}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <Select value={face2} onChange={(val) => handleChange(val, 'face2')} style={{width:"100%"}}>
                                                        <option value="">Select face</option>
                                                        <option value="top">Top</option>
                                                        <option value="bottom">Bottom</option>
                                                        <option value="left">Left</option>
                                                        <option value="right">Right</option>
                                                        <option value="back">Back</option>
                                                        <option value="front">Front</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex_between">
                                            <div>Flip axis direction</div>
                                            <div>
                                                <Checkbox checked={flip_axes} onClick={() => set_flip_axes(!flip_axes)} />
                                            </div>
                                        </div>
                                        <Button style={{fontSize:"12px"}} onClick={onclick_create_scenario} className='primary_button_default'>{does_scenario_already_exist()?"Edit Scenario":"Create Scenario"}</Button>
                                    </div>
                                </ICollpsable>
                                {
                                    cam_mode_data && cam_mode_data.scenarios && cam_mode_data.scenarios.length ? cam_mode_data.scenarios.map((scenario, idx) => (
                                        // <div className={`mb-3 p-2 mx-2 mes_panel`} style={{ border: ((scenario.active)) ? '2px solid #676878' : '', }} onClick={() => { window.Module.set_cam_mode_active_connector_set(scenario.id); update_view(); }}>
                                        //     <div className='mb-2' style={{ color: COLORS.gray9, fontWeight: 700 }}>{"Scenario " + (idx + 1)}</div>
                                        // </div>
                                        <ICollpsable 
                                            header={
                                                <div className="flex_between inf-w-full">
                                                    <div className="flex_property inf-gap-2">
                                                        {`Scenario ${idx + 1}`}
                                                        {isNonEmptyArray(scenario.cam_applicator_ids) ? scenario.cam_applicator_ids.map((x, idx) => <ConnectorLegend key={idx} />) : ''}
                                                    </div>
                                                    <div className="flex_property inf-ga-2">
                                                        {
                                                            getScenariosViolationTooltip(scenario) &&
                                                                <Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{width: '400px', maxWidth: 'unset'}} overlayInnerStyle={{maxHeight: '500px', overflow: 'auto'}} title={getScenariosViolationTooltip(scenario)}>
                                                                    <Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'warning'} style={{cursor: 'pointer', color: '#BF2600', fontSize: '16px'}}></Icons>
                                                                </Tooltip>
                                                        }
                                                        {
                                                            <Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{width: '400px', maxWidth: 'unset'}} overlayInnerStyle={{maxHeight: '500px', overflow: 'auto'}} title={
                                                                <div className="flex_column inf-gap-2">
                                                                    {
                                                                        scenario.attached_panels && scenario.attached_panels.length ?
                                                                            <>
                                                                                <div className="flex_property inf-justify-between">Panel 1: <div style={{fontWeight: 500}}>{scenario.attached_panels[0].unique_label} {panel_types_map[scenario.attached_panels[0].panel_type] && panel_types_map[scenario.attached_panels[0].panel_type].name}</div></div>
                                                                                <div className="flex_property inf-justify-between">Face 1: <div style={{fontWeight: 500}}>{scenario.attached_panels[0].side}</div></div>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        scenario.attached_panels && scenario.attached_panels.length > 1 ?
                                                                            <>

                                                                                <div className="flex_property inf-justify-between">Panel 2: <div style={{fontWeight: 500}}>{scenario.attached_panels[1].unique_label} {panel_types_map[scenario.attached_panels[1].panel_type] && panel_types_map[scenario.attached_panels[1].panel_type].name}</div></div>
                                                                                <div className="flex_property inf-justify-between">Face 2: <div style={{fontWeight: 500}}>{scenario.attached_panels[1].side}</div></div>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        <div className="flex_property inf-justify-between">Connector Source: <div style={{fontWeight: 500}}>{scenario.connection_type && scenario.connection_type.includes("custom") ? "Manual" : "Rule" }</div></div>
                                                                    }
                                                                    {
                                                                        scenario.connection_type && scenario.connection_type.includes("default") && Object.keys(scenario.source_rule_mapping).length ? 
                                                                            <>
                                                                                <ITable rowSeparated={true} coloumnSeparated={true} style_container={{border: '1px solid var(--light-border-color)'}}>
                                                                                    <ITableHeader>
                                                                                        <ITH>Connector</ITH>
                                                                                        <ITH>Rule Name</ITH>
                                                                                    </ITableHeader>
                                                                                    <ITableBody>
                                                                                        {
                                                                                            Object.keys(scenario.source_rule_mapping).map(connector_id => (
                                                                                                <ITableRow>
                                                                                                    <ITD>{cam_mode_data.connectors.find(x => x.id === connector_id).name}</ITD>
                                                                                                    <ITD>{scenario.source_rule_mapping[connector_id]}</ITD>
                                                                                                </ITableRow>
                                                                                            ))
                                                                                        }
                                                                                    </ITableBody>
                                                                                </ITable>
                                                                            </>

                                                                        : ''
                                                                    }
                                                                </div>
                                                            }>
                                                                <Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'info'}></Icons>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </div>
                                            } 
                                            toggle={() => toggle(scenario)} isOpen={scenario.active} style_header={{borderBottom: !scenario.active ? '1px solid var(--light-border-color, #c5c7cf)' : '' }}>
                                            <div className="flex_column inf-gap-2">
                                                <div /* className="inf-flex inf-gap-2" */ className="flex_between">
                                                    {/* <div onClick={add_on_click} className="flex_property flex-1-mw inf-gap-2 cp" style={{border: '1px solid #0078ff', borderRadius: '4px', padding: '4px 8px', color: '#0078ff'}}>
                                                        <Icons name={'add'}></Icons>
                                                        <div>Add new connector</div>
                                                    </div> */}
                                                    {/* <Dropdown trigger={['click']} menu={{
                                                        items: [
                                                            {
                                                                label: <div className="flex_property inf-gap-2" onClick={change_on_click}>
                                                                    <Icons name={'edit'}></Icons>
                                                                    <div>Change connector</div>
                                                                </div>,
                                                                key: 0
                                                            },
                                                            {
                                                                label: <div className="flex_property inf-gap-2" onClick={remove_on_click}>
                                                                    <Icons name={'remove'}></Icons>
                                                                    <div>Remove connector</div>
                                                                </div>,
                                                                key: 1
                                                            }
                                                        ]
                                                    }}>
                                                        <div className="flex_property inf-gap-2 cp inf-border" style={{borderRadius: '4px', padding: '4px', fontSize: '16px'}}>
                                                            <Icons name={'horizontal_options'}></Icons>
                                                        </div>
                                                    </Dropdown> */}
                                                    <div className="flex_property inf-gap-2">
                                                        <IButton size={"small"} outline={true} onClick={add_on_click} color={'secondary'} className={'flex_property inf-gap-2'}><Icons name={'add'}></Icons>Add connector</IButton>
                                                        <IButton disabled={!isNonEmptyArray(scenario.cam_applicator_ids)} size={"small"} outline={true} onClick={change_on_click} color={'secondary'} className={'flex_property inf-gap-2'}><Icons name={'edit'}></Icons>Change</IButton>
                                                    </div>
                                                    <div className="flex_property inf-gap-2">
                                                        <IButton disabled={!isNonEmptyArray(scenario.cam_applicator_ids)} size={"small"} outline={true} onClick={remove_on_click} color={'warning'} className={'flex_property inf-gap-2'}><Icons name={'remove'}></Icons>Remove</IButton>
                                                        <ButtonsDropdown button_list={
                                                            [ {
                                                                disabled: !is_scenario_deletable(scenario),
                                                                tooltip: is_scenario_deletable(scenario)?"":"Only custom scenarios can be edited",
                                                                display_name:(<div style={{color:"black"}}>Edit Scenario</div>),
                                                                customOnClick: () => {edit_scenario(scenario.id)} 
                                                            },{
                                                                disabled: !is_scenario_deletable(scenario),
                                                                tooltip: is_scenario_deletable(scenario)?"":"Only custom scenarios can be deleted",
                                                                display_name:(<div style={{color:"red"}}>Remove Scenario</div>),
                                                                customOnClick: () => {remove_scenario(scenario.id)} 
                                                            }]}
                                                                customToggle={
                                                                    <div className='inf-p-1 cp rounded inf-border'>
                                                                        <Icons name={'horizontal_options'} style={{fontSize: '16px'}} />
                                                                    </div>
                                                                } 
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    isNonEmptyArray(scenario.cam_applicator_ids) ? scenario.cam_applicator_ids.map(cam_applicator_id => (
                                                        <CamFlowRightPanelCard applicator={cam_mode_data && cam_mode_data.connectors && cam_mode_data.connectors.find(x => x.id === cam_applicator_id)} applicator_card_props={{collision_info: getConectorViolationTooltip(scenario, cam_applicator_id)}}/>
                                                    )) : ''
                                                }
                                            </div>
                                        </ICollpsable>
                                    )) : ''
                                }
                            </div>
                        </div>
                        :
                        <div className="inf-p-2" style={{ height: '100%' }}>
                            <div className="flex_center" style={{ height: '100%', background: '#EAECF0', border: '2px dashed #A7A8B2' }}>
                                <div style={{ maxWidth: '250px', fontWeight: 500, color: '#676878', fontSize: '16px', textAlign: 'center' }}>
                                    Select a panel on 3D viewer or on panel listing in left panel to see the panel connectors here
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}

export default CamFlowRightPanel